import {
  importUrlSuccess as importUrlSuccessWrap,
  uploadFileSuccess as uploadFileSuccessWrap,
  dropFileSuccess as dropFileSuccessWrap,
} from './wrap-actions.js';

const EditorContentFromFilePlugin = () => {
  return {
    statePlugins: {
      editor: {
        wrapActions: {
          importUrlSuccess: importUrlSuccessWrap,
          uploadFileSuccess: uploadFileSuccessWrap,
        },
      },
      editorDropzone: {
        wrapActions: {
          dropFileSuccess: dropFileSuccessWrap,
        },
      },
    },
  };
};

export default EditorContentFromFilePlugin;
