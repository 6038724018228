import { StandaloneServices, IContextKeyService } from 'vscode/services';

import createOpenCommentsPanelActionDescriptor from './actions/open-comments-panel.js';

const activate = ({ system, disposables }) => {
  const { monaco } = system;

  system.monacoInitializationDeferred().promise.then(() => {
    const contextKeyService = StandaloneServices.get(IContextKeyService);
    disposables.push(contextKeyService.setContext('isCommentsPanelClosed', true));
  });

  disposables.push(
    monaco.editor.onDidCreateEditor((editor) => {
      disposables.push(
        monaco.editor.onDidCreateModel(() => {
          const openCommentsPanelActionDescriptor = createOpenCommentsPanelActionDescriptor(system);

          if (!editor.getAction(openCommentsPanelActionDescriptor.id)) {
            disposables.push(editor.addAction(openCommentsPanelActionDescriptor));
          }
        })
      );
    })
  );

  return disposables;
};

export default activate;
