const activate = ({ system, disposables }) => {
  const {
    monaco,
    editorActions,
    editorSelectors,
    editorMonacoComments: { decorationsCollections },
  } = system;

  disposables.push(
    monaco.editor.onDidCreateEditor((editor) => {
      decorationsCollections.iconIndicator = editor.createDecorationsCollection(); // eslint-disable-line no-param-reassign
    })
  );

  // focus comment highlight based on comment icon indicator interaction
  disposables.push(
    monaco.editor.onDidCreateEditor((editor) => {
      disposables.push(
        editor.onMouseDown((event) => {
          const { range, type } = event.target;

          if (type === monaco.editor.MouseTargetType.GUTTER_GLYPH_MARGIN) {
            const decorationsInRange = editor
              .getModel()
              .getDecorationsInRange(range)
              .filter(
                (decoration) =>
                  decorationsCollections.iconIndicator.has(decoration) &&
                  // decorations with start column equal to end column of range are displayed on the next surrogate line
                  decoration.range.startColumn !== range.endColumn
              )
              .sort(
                (a, b) =>
                  a.range.startColumn - b.range.startColumn ||
                  a.options.metadata.comment.created - b.options.metadata.comment.created
              );

            if (!decorationsInRange.length) return;

            // rotating the active comment when clicking multiple times
            const activeCommentId = editorSelectors.selectActiveComment();
            const activeDecorationIndex = decorationsInRange.findIndex(
              (decoration) => decoration.options.metadata.comment.id === activeCommentId
            );
            const nextActiveDecorationIndex =
              (activeDecorationIndex + 1) % decorationsInRange.length;
            const nextActiveCommentId =
              decorationsInRange[nextActiveDecorationIndex].options.metadata.comment.id;
            const nextCursorPosition =
              decorationsInRange[nextActiveDecorationIndex].range.getStartPosition();

            editorActions.setPosition(nextCursorPosition, { scroll: false });
            editorActions.setActiveComment(nextActiveCommentId);
            editorActions.openCommentsPanel();
          }
        })
      );
    })
  );

  return disposables;
};

export default activate;
