import createAddCommentActionDescriptor from './actions/add-comment.js';

const activate = ({ system, disposables }) => {
  const { monaco } = system;

  disposables.push(
    monaco.editor.onDidCreateEditor((editor) => {
      disposables.push(
        monaco.editor.onDidCreateModel(() => {
          const addCommentActionDescriptor = createAddCommentActionDescriptor(system);

          if (!editor.getAction(addCommentActionDescriptor.id)) {
            disposables.push(editor.addAction(addCommentActionDescriptor));
          }
        })
      );
    })
  );

  return disposables;
};

export default activate;
