const createOpenCommentsPanelActionDescriptor = ({ editorActions }) => ({
  id: 'editor.action.openCommentsPanel',
  label: 'Open Comments panel',
  precondition: 'isCommentsPanelClosed',
  contextMenuGroupId: 'comments',
  contextMenuOrder: 1,
  run() {
    editorActions.openCommentsPanel();
  },
});

export default createOpenCommentsPanelActionDescriptor;
