import { fromJS, List } from 'immutable';

import {
  EDITOR_MONACO_SET_COMMENTS,
  EDITOR_MONACO_SET_ACTIVE_COMMENT,
  EDITOR_MONACO_OPEN_COMMENTS_PANEL,
  EDITOR_MONACO_CLOSE_COMMENTS_PANEL,
  EDITOR_MONACO_SHOW_COMMENT_ADD_FORM,
  EDITOR_MONACO_HIDE_COMMENT_ADD_FORM,
  EDITOR_MONACO_ADD_NEW_COMMENT,
} from './actions.js';
import { CommentEntityState } from './root-injects.js';

export const initialState = {
  // @TODO(vladimir.gorej@gmail.com): this needs to be set to empty List() when ready for production
  comments: fromJS([
    {
      id: '0FKSVD24i7',
      text: 'First comment - overlapping line 2',
      created: 1712710023,
      entityState: CommentEntityState.Unchanged,
      range: {
        startLineNumber: 2,
        startColumn: 1,
        endLineNumber: 2,
        endColumn: 6,
      },
    },
    {
      id: 'uqi8of9zr8',
      text: 'Second comment',
      created: 1716894994,
      entityState: CommentEntityState.Unchanged,
      range: {
        startLineNumber: 3,
        startColumn: 1,
        endLineNumber: 5,
        endColumn: 20,
      },
    },
    {
      id: 'zXJcwElRRc',
      text: 'Third comment',
      created: 1713702944,
      entityState: CommentEntityState.Unchanged,
      range: {
        startLineNumber: 10,
        startColumn: 1,
        endLineNumber: 10,
        endColumn: 10,
      },
    },
    {
      id: 'OmLyDmELx5',
      text: 'Fourth comment',
      created: 1711308329,
      entityState: CommentEntityState.Unchanged,
      range: {
        startLineNumber: 3,
        startColumn: 1,
        endLineNumber: 7,
        endColumn: 10,
      },
    },
    {
      id: 'XjkSU5w0c6',
      text: 'Fifth comment - overlapping line 2',
      created: 1708515522,
      entityState: CommentEntityState.Unchanged,
      range: {
        startLineNumber: 2,
        startColumn: 1,
        endLineNumber: 2,
        endColumn: 3,
      },
    },
    {
      id: 'HUlg3sMGia',
      text: 'Sixth comment - overlapping line 2',
      created: 1714108398,
      entityState: CommentEntityState.Unchanged,
      range: {
        startLineNumber: 2,
        startColumn: 3,
        endLineNumber: 2,
        endColumn: 6,
      },
    },
    {
      id: '7UBWEFSK0Z',
      text: 'Sixth comment - overlapping line 2',
      created: 1714671166,
      entityState: CommentEntityState.Unchanged,
      range: {
        text: 'Sixth comment - overlapping line 2',
        startLineNumber: 1,
        startColumn: 1,
        endLineNumber: 1,
        endColumn: 7,
      },
    },
    {
      id: 'KPVqy3Nvkk',
      text: 'Eighth comment - overlapping line 1',
      created: 1710736770,
      entityState: CommentEntityState.Unchanged,
      range: {
        startLineNumber: 1,
        startColumn: 1,
        endLineNumber: 1,
        endColumn: 11,
      },
    },
    {
      id: 'GpIR0cpQwX',
      text: 'Ninth comment - overlapping line 1',
      created: 1721400078,
      entityState: CommentEntityState.Unchanged,
      range: {
        startLineNumber: 1,
        startColumn: 5,
        endLineNumber: 1,
        endColumn: 8,
      },
    },
    {
      id: 'QSc80xQXwP',
      text: 'Tenth comment - on wrapped line 26',
      created: 1721190549,
      entityState: CommentEntityState.Unchanged,
      range: {
        startLineNumber: 26,
        startColumn: 90,
        endLineNumber: 26,
        endColumn: 100,
      },
    },
    {
      id: 'NNsD4vKydd',
      text: 'Eleventh comment - on wrapped line 26',
      created: 1722194311,
      entityState: CommentEntityState.Unchanged,
      range: {
        startLineNumber: 26,
        startColumn: 30,
        endLineNumber: 26,
        endColumn: 40,
      },
    },
    {
      id: '5lxd6Ull1K',
      text: 'Twelfth comment - on line 10',
      created: 1720271427,
      entityState: CommentEntityState.Unchanged,
      range: {
        startLineNumber: 10,
        startColumn: 12,
        endLineNumber: 10,
        endColumn: 25,
      },
    },
    {
      id: 'oj8fy68d8X',
      text: 'Thirteenth comment - on wrapped line 28',
      created: 1721190549,
      entityState: CommentEntityState.Unchanged,
      range: {
        startLineNumber: 28,
        startColumn: 59,
        endLineNumber: 28,
        endColumn: 65,
      },
    },
    {
      id: 'sFgeevKmEn',
      text: 'Fourteenth comment - on wrapped line 28',
      created: 1722194311,
      entityState: CommentEntityState.Unchanged,
      range: {
        startLineNumber: 28,
        startColumn: 30,
        endLineNumber: 28,
        endColumn: 35,
      },
    },
  ]),
  activeCommentId: null,
  isCommentsPanelOpen: false,
  isCommentAddFormDisplayed: false,
};

const reducers = {
  [EDITOR_MONACO_SET_COMMENTS]: (state, action) => {
    return state.set('comments', fromJS(action.payload));
  },
  [EDITOR_MONACO_SET_ACTIVE_COMMENT]: (state, action) => {
    return state.set('activeCommentId', action.payload);
  },
  [EDITOR_MONACO_OPEN_COMMENTS_PANEL]: (state) => {
    return state.set('isCommentsPanelOpen', true);
  },
  [EDITOR_MONACO_CLOSE_COMMENTS_PANEL]: (state) => {
    return state.set('isCommentsPanelOpen', false);
  },
  [EDITOR_MONACO_SHOW_COMMENT_ADD_FORM]: (state) => {
    return state.set('isCommentAddFormDisplayed', true);
  },
  [EDITOR_MONACO_HIDE_COMMENT_ADD_FORM]: (state) => {
    return state.set('isCommentAddFormDisplayed', false);
  },
  [EDITOR_MONACO_ADD_NEW_COMMENT]: (state, action) => {
    const comments = state.get('comments', List());
    const comment = fromJS({ ...action.payload, entityState: CommentEntityState.Added });

    return state.set('comments', comments.push(comment));
  },
};

export default reducers;
