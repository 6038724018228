import fileDialog from 'file-dialog';

/**
 * Action types.
 */
export const EDITOR_UPLOAD_FILE_STARTED = 'editor_upload_file_started';
export const EDITOR_UPLOAD_FILE_SUCCESS = 'editor_upload_file_success';
export const EDITOR_UPLOAD_FILE_FAILURE = 'editor_upload_file_failure';

/**
 * Action creators.
 */
export const uploadFileStarted = () => ({
  type: EDITOR_UPLOAD_FILE_STARTED,
});

export const uploadFileSuccess = ({ content, file }) => ({
  type: EDITOR_UPLOAD_FILE_SUCCESS,
  payload: content,
  meta: { file },
});

export const uploadFileFailure = ({ error }) => {
  return {
    type: EDITOR_UPLOAD_FILE_FAILURE,
    payload: error,
    error: true,
  };
};

/**
 * Async thunks.
 */
export const uploadFile = () => async (system) => {
  const { editorActions } = system;

  editorActions.uploadFileStarted();

  try {
    const [file] = await fileDialog({ multiple: false });
    const content = await file.text();

    return editorActions.uploadFileSuccess({
      content,
      file,
    });
  } catch (error) {
    return editorActions.uploadFileFailure({
      error,
    });
  }
};
