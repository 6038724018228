import React from 'react';
import PropTypes from 'prop-types';

const CommentsPanel = ({ getComponent, editorActions, editorSelectors }) => {
  const CommentAddForm = getComponent('EditorCommentAddForm', true);
  const isCommentAddFormDisplayed = editorSelectors.selectIsCommentAddFormDisplayed();

  return (
    <div className="comments-panel-container">
      <div className="comments-panel">
        <div className="comments-panel__header">
          <button type="button" onClick={editorActions.closeCommentsPanel}>
            <span aria-hidden="true">x</span>
          </button>
        </div>
        {isCommentAddFormDisplayed && <CommentAddForm editorActions={editorActions} />}
      </div>
    </div>
  );
};

CommentsPanel.propTypes = {
  getComponent: PropTypes.func.isRequired,
  editorActions: PropTypes.shape({
    closeCommentsPanel: PropTypes.func.isRequired,
  }).isRequired,
  editorSelectors: PropTypes.shape({
    selectIsCommentAddFormDisplayed: PropTypes.func.isRequired,
  }).isRequired,
};

export default CommentsPanel;
