import createSafeActionWrapper from '../../utils/create-safe-action-wrapper.js';
import { IDLE_STATUS, FAILURE_STATUS } from './reducers.js';

export const detectContentTypeSuccess = createSafeActionWrapper((oriAction, system) => () => {
  const { editorSelectors, editorTopBarSelectors, editorTopBarActions } = system;
  const triggerStatuses = [IDLE_STATUS, FAILURE_STATUS];

  if (
    triggerStatuses.includes(editorTopBarSelectors.selectOpenAPI3GeneratorServerListStatus()) &&
    (editorSelectors.selectIsContentTypeOpenAPI30x() ||
      editorSelectors.selectIsContentTypeOpenAPI31x())
  ) {
    editorTopBarActions.fetchOpenAPI3GeneratorServerList();
  }

  if (
    triggerStatuses.includes(editorTopBarSelectors.selectOpenAPI3GeneratorClientListStatus()) &&
    (editorSelectors.selectIsContentTypeOpenAPI30x() ||
      editorSelectors.selectIsContentTypeOpenAPI31x())
  ) {
    editorTopBarActions.fetchOpenAPI3GeneratorClientList();
  }

  if (
    triggerStatuses.includes(editorTopBarSelectors.selectOpenAPI2GeneratorServerListStatus()) &&
    editorSelectors.selectIsContentTypeOpenAPI20()
  ) {
    editorTopBarActions.fetchOpenAPI2GeneratorServerList();
  }

  if (
    triggerStatuses.includes(editorTopBarSelectors.selectOpenAPI2GeneratorClientListStatus()) &&
    editorSelectors.selectIsContentTypeOpenAPI20()
  ) {
    editorTopBarActions.fetchOpenAPI2GeneratorClientList();
  }
});

export const importUrlSuccess = createSafeActionWrapper((oriAction, system) => ({ definition }) => {
  const { editorActions, EditorContentOrigin } = system;

  editorActions.setContent(definition, EditorContentOrigin.ImportUrl);
});

export const uploadFileSuccess = createSafeActionWrapper((oriAction, system) => ({ content }) => {
  const { editorActions, EditorContentOrigin } = system;

  editorActions.setContent(content, EditorContentOrigin.ImportFile);
});
