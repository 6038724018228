import {
  setComments,
  setActiveComment,
  openCommentsPanel,
  closeCommentsPanel,
  showCommentAddForm,
  hideCommentAddForm,
  addNewComment,
} from './actions.js';
import reducers from './reducers.js';
import {
  selectComments,
  selectActiveComment,
  selectIsCommentsPanelOpen,
  selectIsCommentAddFormDisplayed,
} from './selectors.js';
import {
  setComments as setCommentsWrap,
  setActiveComment as setActiveCommentWrap,
  openCommentsPanel as openCommentsPanelWrap,
  closeCommentsPanel as closeCommentsPanelWrap,
  addNewComment as addNewCommentWrap,
} from './wrap-actions.js';
import { decorationsCollections, makeInitialized, CommentEntityState } from './root-injects.js';
import makeAfterLoad from './after-load.js';
import CommentsPanel from './components/CommentsPanel/CommentsPanel.jsx';
import CommentAddForm from './components/CommentAddForm/CommentAddForm.jsx';
import EditorPreviewPaneWrapper from './wrap-components/EditorPreviewPaneWrapper.jsx';

const EditorMonacoCommentsPlugin = (opts = {}) => {
  const isCalledWithGetSystem = typeof opts.getSystem === 'function';
  const options = isCalledWithGetSystem ? {} : opts;
  const plugin = (system) => {
    return {
      afterLoad: makeAfterLoad(options),
      rootInjects: {
        editorMonacoComments: {
          decorationsCollections,
          initialized: makeInitialized(system),
          CommentEntityState,
        },
      },
      components: {
        EditorCommentsPanel: CommentsPanel,
        EditorCommentAddForm: CommentAddForm,
      },
      wrapComponents: {
        EditorPreviewPane: EditorPreviewPaneWrapper,
      },
      statePlugins: {
        editor: {
          actions: {
            setComments,
            addNewComment,
            setActiveComment,
            openCommentsPanel,
            closeCommentsPanel,
            showCommentAddForm,
            hideCommentAddForm,
          },
          wrapActions: {
            setComments: setCommentsWrap,
            setActiveComment: setActiveCommentWrap,
            openCommentsPanel: openCommentsPanelWrap,
            closeCommentsPanel: closeCommentsPanelWrap,
            addNewComment: addNewCommentWrap,
          },
          selectors: {
            selectComments,
            selectActiveComment,
            selectIsCommentsPanelOpen,
            selectIsCommentAddFormDisplayed,
          },
          reducers,
        },
      },
    };
  };

  return isCalledWithGetSystem ? plugin(opts) : plugin;
};

export default EditorMonacoCommentsPlugin;
