export const decorationsCollections = {
  iconIndicator: null,
  gutterLine: null,
  highlight: null,
};

export const makeInitialized = (system) => () => {
  const {
    editorMonacoComments: { decorationsCollections: dc },
  } = system.getSystem();

  return !!dc.iconIndicator && !!dc.gutterLine && !!dc.highlight;
};

export const CommentEntityState = {
  Unchanged: 'unchanged',
  Modified: 'modified',
  Deleted: 'deleted',
  Added: 'added',
};
