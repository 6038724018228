import { StandaloneServices, IContextKeyService } from 'vscode/services';

import createSafeActionWrapper from '../../utils/create-safe-action-wrapper.js';

export const setComments = createSafeActionWrapper((oriAction, system) => async () => {
  const { editorSelectors, fn, editorMonacoComments } = system;
  const { decorationsCollections } = editorMonacoComments;

  await fn.waitUntil(editorMonacoComments.initialized);

  const iconIndicatorDecorations = editorSelectors.selectCommentIconIndicatorDecorations();
  const gutterLineDecorations = editorSelectors.selectCommentGutterLineDecorations();
  const highlightDecorations = editorSelectors.selectCommentHighlightDecorations();

  decorationsCollections.iconIndicator.set(iconIndicatorDecorations);
  decorationsCollections.gutterLine.set(gutterLineDecorations);
  decorationsCollections.highlight.set(highlightDecorations);
});

export const setActiveComment = createSafeActionWrapper((oriAction, system) => () => {
  const { editorSelectors, editorMonacoComments } = system;
  const { decorationsCollections } = editorMonacoComments;
  const highlightDecorations = editorSelectors.selectCommentHighlightDecorations();

  decorationsCollections.highlight.set(highlightDecorations);
});

export const openCommentsPanel = createSafeActionWrapper((oriAction, system) => () => {
  system.monacoInitializationDeferred().promise.then(() => {
    const contextKeyService = StandaloneServices.get(IContextKeyService);
    contextKeyService.setContext('isCommentsPanelClosed', false);
  });
});

export const closeCommentsPanel = createSafeActionWrapper((oriAction, system) => () => {
  system.monacoInitializationDeferred().promise.then(() => {
    const contextKeyService = StandaloneServices.get(IContextKeyService);
    contextKeyService.setContext('isCommentsPanelClosed', true);
  });
});

export const addNewComment =
  (oriAction, system) =>
  (...args) => {
    const { payload: comment } = oriAction(...args);
    const { editorMonacoComments, editorSelectors, editorActions } = system;
    const { decorationsCollections } = editorMonacoComments;
    const matchByCommentId = (decoration) => decoration.options.metadata.comment.id === comment.id;

    const iconIndicatorDecoration = editorSelectors
      .selectCommentIconIndicatorDecorations()
      .find(matchByCommentId);
    const gutterLineDecoration = editorSelectors
      .selectCommentGutterLineDecorations()
      .find(matchByCommentId);

    decorationsCollections.iconIndicator.append([iconIndicatorDecoration]);
    decorationsCollections.gutterLine.append([gutterLineDecoration]);
    // highlight decorations will be redrawn after setting the active comment
    editorActions.setActiveComment(comment.id);
  };
