import ShortUniqueId from 'short-unique-id';

export const EDITOR_MONACO_SET_COMMENTS = 'EDITOR_MONACO_SET_COMMENTS';
export const EDITOR_MONACO_SET_ACTIVE_COMMENT = 'EDITOR_MONACO_SET_ACTIVE_COMMENT';
export const EDITOR_MONACO_OPEN_COMMENTS_PANEL = 'EDITOR_MONACO_OPEN_COMMENTS_PANEL';
export const EDITOR_MONACO_CLOSE_COMMENTS_PANEL = 'EDITOR_MONACO_CLOSE_COMMENTS_PANEL';
export const EDITOR_MONACO_SHOW_COMMENT_ADD_FORM = 'EDITOR_MONACO_SHOW_COMMENT_ADD_FORM';
export const EDITOR_MONACO_HIDE_COMMENT_ADD_FORM = 'EDITOR_MONACO_HIDE_COMMENT_ADD_FORM';
export const EDITOR_MONACO_ADD_NEW_COMMENT = 'EDITOR_MONACO_ADD_NEW_COMMENT';

export const setComments = (comments) => ({
  type: EDITOR_MONACO_SET_COMMENTS,
  payload: comments,
});

export const addNewComment = ({ id, text, range, created }) => {
  const uid = new ShortUniqueId({ length: 10 });

  return {
    type: EDITOR_MONACO_ADD_NEW_COMMENT,
    payload: {
      id: id || uid(),
      text,
      range: {
        startLineNumber: range.startLineNumber,
        startColumn: range.startColumn,
        endLineNumber: range.endLineNumber,
        endColumn: range.endColumn,
      },
      created: created || Math.round(Date.now() / 1000),
    },
  };
};

export const setActiveComment = (commentId) => ({
  type: EDITOR_MONACO_SET_ACTIVE_COMMENT,
  payload: commentId,
});

export const openCommentsPanel = () => ({
  type: EDITOR_MONACO_OPEN_COMMENTS_PANEL,
});

export const closeCommentsPanel = () => ({
  type: EDITOR_MONACO_CLOSE_COMMENTS_PANEL,
});

export const showCommentAddForm = () => ({
  type: EDITOR_MONACO_SHOW_COMMENT_ADD_FORM,
});

export const hideCommentAddForm = () => ({
  type: EDITOR_MONACO_HIDE_COMMENT_ADD_FORM,
});
