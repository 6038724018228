/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

const EditorPreviewPaneWrapper = (Original, system) => {
  const EditorPreview = ({ getComponent, editorSelectors }) => {
    const EditorCommentsPanel = getComponent('EditorCommentsPanel', true);
    const isCommentsPanelOpen = editorSelectors.selectIsCommentsPanelOpen();

    return (
      <>
        {isCommentsPanelOpen && <EditorCommentsPanel />}
        <Original {...system} />
      </>
    );
  };

  EditorPreview.propTypes = {
    getComponent: PropTypes.func.isRequired,
    editorSelectors: PropTypes.shape({
      selectIsCommentsPanelOpen: PropTypes.func.isRequired,
    }).isRequired,
  };

  return EditorPreview;
};

export default EditorPreviewPaneWrapper;
