import createSafeActionWrapper from '../../utils/create-safe-action-wrapper.js';

export const detectContentTypeSuccess = createSafeActionWrapper(
  (oriAction, system) =>
    ({ content }) => {
      const { specActions, editorSelectors, editorPreviewSwaggerUISelectors, EditorContentOrigin } =
        system;

      const contentOrigin = editorSelectors.selectContentOrigin();

      // all content in editor was deleted
      if (contentOrigin === EditorContentOrigin.Editor && !content.trim()) {
        specActions.updateUrl('');
      }

      if (editorSelectors.selectIsContentTypeOpenAPI()) {
        if (contentOrigin === EditorContentOrigin.ImportUrl) {
          specActions.updateUrl(editorPreviewSwaggerUISelectors.selectURL());
        } else if (contentOrigin !== EditorContentOrigin.Editor) {
          specActions.updateUrl('');
        }

        specActions.updateSpec(content, EditorContentOrigin.Editor);
      }
    }
);

export const previewUnmounted = createSafeActionWrapper((oriAction, system) => () => {
  system.specActions.updateUrl('');
  system.specActions.updateSpec('', system.EditorContentOrigin.Editor);
});

export const jumpToPathSuccess = createSafeActionWrapper((oriAction, system) => ({ path }) => {
  const { authActions } = system;
  const isAuthPath =
    path[0] === 'securityDefinitions' ||
    (path[0] === 'components' && path[1] === 'securitySchemes');

  if (isAuthPath) {
    authActions.showDefinitions(false);
  }
});
