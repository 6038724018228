export const EDITOR_SET_POSITION_STARTED = 'editor_set_position_started';
export const EDITOR_SET_POSITION_SUCCESS = 'editor_set_position_success';
export const EDITOR_SET_POSITION_FAILURE = 'editor_set_position_failure';

export const setPositionStarted = ({ lineNumber, column, options }) => ({
  type: EDITOR_SET_POSITION_STARTED,
  payload: { lineNumber, column },
  meta: { options },
});

export const setPositionSuccess = ({ lineNumber, column, options }) => ({
  type: EDITOR_SET_POSITION_SUCCESS,
  payload: { lineNumber, column },
  meta: { options },
});

export const setPositionFailure = ({ lineNumber, column, options, error }) => ({
  type: EDITOR_SET_POSITION_FAILURE,
  error: true,
  payload: error,
  meta: { lineNumber, column, options },
});

export const setPosition =
  ({ lineNumber = 0, column = 0 } = {}, options = { scroll: true }) =>
  (system) => {
    const { editorActions, editorSelectors } = system;

    editorActions.setPositionStarted({ lineNumber, column, options });

    try {
      const editor = editorSelectors.selectEditor();

      if (options.scroll) {
        editor.revealPositionNearTop({ lineNumber, column });
      }

      editor.setPosition({ lineNumber, column });
      editor.focus();

      return editorActions.setPositionSuccess({ lineNumber, column, options });
    } catch (error) {
      return editorActions.setPositionFailure({ error, lineNumber, column, options });
    }
  };
