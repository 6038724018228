import { ModelDecorationOptions as ModelDecorationOptionsBase } from 'vscode/vscode/vs/editor/common/model/textModel';

class ModelDecorationOptions extends ModelDecorationOptionsBase {
  metadata = undefined;

  constructor(options) {
    super(options);

    this.metadata = options.metadata;
  }
}

export default ModelDecorationOptions;
