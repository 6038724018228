const activate = ({ system, disposables }) => {
  const {
    monaco,
    editorActions,
    editorMonacoComments: { decorationsCollections },
  } = system;

  // create comments highlight decorations collection
  disposables.push(
    monaco.editor.onDidCreateEditor((editor) => {
      decorationsCollections.highlight = editor.createDecorationsCollection(); // eslint-disable-line no-param-reassign
    })
  );

  // focus comment highlight with cursor on it
  disposables.push(
    monaco.editor.onDidCreateEditor((editor) => {
      disposables.push(
        editor.onDidChangeCursorPosition((event) => {
          const { position, source } = event;
          const editorModel = editor.getModel();
          const range = monaco.Range.fromPositions(position);

          // source 'api' means that cursor position change was triggered programmatically
          if (source === 'api') return;

          const [smallestDecoration] = editorModel
            .getDecorationsInRange(range)
            .filter((decoration) => decorationsCollections.highlight.has(decoration))
            .map((decoration) => [
              decoration.options.metadata.comment.id,
              editorModel.getValueLengthInRange(decoration.range),
            ])
            .sort((a, b) => a[1] - b[1]); // sort by length, ascending

          if (smallestDecoration) {
            editorActions.setActiveComment(smallestDecoration[0]);
          } else {
            editorActions.setActiveComment(null);
          }
        })
      );
    })
  );

  return disposables;
};

export default activate;
