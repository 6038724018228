import {
  selectCommentIconIndicatorDecorations,
  selectCommentGutterLineDecorations,
  selectCommentHighlightDecorations,
} from './selectors.js';
import { initialState } from './reducers.js';
import lazyMonacoContribution from './monaco.contribution/index.js';

const makeAfterLoad = ({ comments }) => {
  return async function afterLoad(system) {
    const { monaco, fn, editorMonacoComments } = system.getSystem();
    const usingEditorMonacoPlugin = typeof monaco !== 'undefined';
    const { editor: pluginAPI } = this.statePlugins;

    if (!usingEditorMonacoPlugin) return;

    // creating system selectors
    if (typeof fn.createSystemSelector === 'function') {
      pluginAPI.selectors.selectCommentIconIndicatorDecorations = fn.createSystemSelector(
        selectCommentIconIndicatorDecorations
      );

      pluginAPI.selectors.selectCommentGutterLineDecorations = fn.createSystemSelector(
        selectCommentGutterLineDecorations
      );

      pluginAPI.selectors.selectCommentHighlightDecorations = fn.createSystemSelector(
        selectCommentHighlightDecorations
      );
    }

    // dispose of previous disposables
    if (pluginAPI.disposables) {
      pluginAPI.disposables.dispose();
      delete pluginAPI.disposables;
    }

    // initialize monaco contribution in lazy way
    const disposables = lazyMonacoContribution({ system });

    // wait for comments decorations collection to be created
    await fn.waitUntil(editorMonacoComments.initialized);

    // assign current disposables to plugin API
    pluginAPI.disposables = disposables;

    // set comments provided as plugin option (or an initial comments)
    if (Array.isArray(comments)) {
      system.editorActions.setComments(comments);
    } else {
      system.editorActions.setComments(initialState.comments);
    }
  };
};

export default makeAfterLoad;
