const createAddCommentActionDescriptor = ({ editorActions }) => ({
  id: 'editor.action.addComment',
  label: 'Add Comment',
  precondition: 'editorHasSelection',
  contextMenuGroupId: 'comments',
  contextMenuOrder: 2,
  run() {
    editorActions.showCommentAddForm();
    editorActions.openCommentsPanel();
  },
});

export default createAddCommentActionDescriptor;
