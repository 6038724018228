// eslint-disable-next-line import/prefer-default-export
export const ContentOrigin = {
  Clear: 'clear',
  Conversion: 'conversion',
  Editor: 'editor',
  FileDrop: 'file-drop',
  FixtureLoad: 'fixture-load',
  ImportFile: 'import-file',
  ImportUrl: 'import-url',
  InitialFixtureLoad: 'initial-fixture-load',
  LocalStorage: 'local-storage',
  NotEditor: 'not-editor',
  Props: 'props',
};
