import Disposable from './Disposable.js';
import { activate as activateGutterLine } from './gutter-line/index.js';
import { activate as activateIconIndicator } from './icon-indicator/index.js';
import { activate as activateHighlight } from './highlight/index.js';
import { activate as activateContextMenuItemPanelOpener } from './context-menu-item-panel-opener/index.js';
import { activate as activateContextMenuItemAddComment } from './context-menu-item-add-comment/index.js';

const lazyMonacoContribution = ({ system }) => {
  const { monaco } = system;
  const disposables = new Disposable();

  activateGutterLine({ system, disposables });
  activateIconIndicator({ system, disposables });
  activateHighlight({ system, disposables });
  activateContextMenuItemPanelOpener({ system, disposables });
  activateContextMenuItemAddComment({ system, disposables });

  // disposing of all allocated disposables
  disposables.push(
    monaco.editor.onDidCreateEditor((editor) => {
      disposables.push(
        editor.onDidDispose(() => {
          disposables.forEach((disposable) => {
            disposable.dispose();
          });
          disposables.length = 0;
        })
      );
    })
  );

  return disposables;
};

export default lazyMonacoContribution;
