import { createSelector } from 'reselect';
import classNames from 'classnames';

import { initialState } from './reducers.js';
import ModelDecorationOptions from './monaco.contribution/ModelDecorationOptions.js';

export const selectComments = createSelector([(state) => state], (state) =>
  state.get('comments', initialState.comments).toJS()
);

export const selectActiveComment = (state) =>
  state.get('activeCommentId', initialState.activeCommentId);

export const selectIsCommentsPanelOpen = (state) =>
  state.get('isCommentsPanelOpen', initialState.isCommentsPanelOpen);

export const selectIsCommentAddFormDisplayed = (state) =>
  state.get('isCommentAddFormDisplayed', initialState.isCommentAddFormDisplayed);

export const selectCommentIconIndicatorDecorations = createSelector(
  [(state, system) => system.editorSelectors.selectComments(), (state, system) => system.monaco],
  (comments, monaco) =>
    comments.map((comment) => ({
      // adding a comment icon - only for the start line
      range: new monaco.Range(
        comment.range.startLineNumber,
        comment.range.startColumn,
        comment.range.startLineNumber,
        comment.range.startColumn
      ),
      options: new ModelDecorationOptions({
        isWholeLine: false,
        glyphMarginClassName: 'codicon-comment codicon-comment--gutter',
        metadata: {
          comment: { id: comment.id, created: comment.created },
        },
      }),
    }))
);

export const selectCommentGutterLineDecorations = createSelector(
  [(state, system) => system.editorSelectors.selectComments(), (state, system) => system.monaco],
  (comments, monaco) =>
    comments.map((comment) => ({
      // adding a comment line - for the entire range
      range: new monaco.Range(
        comment.range.startLineNumber,
        comment.range.startColumn,
        comment.range.endLineNumber,
        comment.range.endColumn
      ),
      options: new ModelDecorationOptions({
        isWholeLine: false,
        linesDecorationsClassName: 'comment-line',
        metadata: {
          comment: { id: comment.id, created: comment.created },
        },
      }),
    }))
);

export const selectCommentHighlightDecorations = createSelector(
  [
    (state, system) => system.editorSelectors.selectComments(),
    (state, system) => system.monaco,
    (state, system) => system.editorSelectors.selectActiveComment(),
  ],
  (comments, monaco, activeCommentId) => {
    const highlightDecorations = comments.map((comment) => ({
      // adding a comment highlight - for the entire range
      range: new monaco.Range(
        comment.range.startLineNumber,
        comment.range.startColumn,
        comment.range.endLineNumber,
        comment.range.endColumn
      ),
      options: new ModelDecorationOptions({
        isWholeLine: false,
        className: classNames('comment-highlight', {
          'comment-highlight--focused': activeCommentId === comment.id,
        }),
        zIndex: 1,
        metadata: {
          comment: { id: comment.id, created: comment.created },
        },
      }),
    }));

    return highlightDecorations;
  }
);
