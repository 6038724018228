/**
 * Action types.
 */
export const EDITOR_DROPZONE_DROP_FILE_STARTED = 'editor_dropzone_drop_file_started';
export const EDITOR_DROPZONE_DROP_FILE_SUCCESS = 'editor_dropzone_drop_file_success';
export const EDITOR_DROPZONE_DROP_FILE_FAILURE = 'editor_dropzone_drop_file_failure';

/**
 * Action creators.
 */
export const dropFileStarted = ({ file }) => ({
  type: EDITOR_DROPZONE_DROP_FILE_STARTED,
  payload: file,
});

export const dropFileSuccess = ({ content, file }) => ({
  type: EDITOR_DROPZONE_DROP_FILE_SUCCESS,
  payload: content,
  meta: { file },
});

export const dropFileFailure = ({ error, file }) => ({
  type: EDITOR_DROPZONE_DROP_FILE_FAILURE,
  payload: error,
  error: true,
  meta: { file },
});

export const dropFile =
  ({ file }) =>
  async (system) => {
    const { editorDropzoneActions } = system;

    editorDropzoneActions.dropFileStarted({ file });

    try {
      const content = await file.text();

      return editorDropzoneActions.dropFileSuccess({
        content,
        file,
      });
    } catch (error) {
      return editorDropzoneActions.dropFileFailure({
        error,
        file,
      });
    }
  };
