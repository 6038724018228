const activate = ({ system, disposables }) => {
  const {
    monaco,
    editorMonacoComments: { decorationsCollections },
  } = system;

  disposables.push(
    monaco.editor.onDidCreateEditor((editor) => {
      decorationsCollections.gutterLine = editor.createDecorationsCollection(); // eslint-disable-line no-param-reassign
    })
  );

  return disposables;
};

export default activate;
