import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const CommentAddForm = ({ editorActions, editorSelectors }) => {
  const [text, setText] = useState('');
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current !== null && !ref.current.contains(event.target)) {
        editorActions.hideCommentAddForm();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editorActions]);

  const handleCancelClick = () => {
    editorActions.hideCommentAddForm();
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleAddCommentClick = () => {
    if (text.trim() === '') return;

    const editor = editorSelectors.selectEditor();

    editorActions.addNewComment({ text, range: editor.getSelection() });
    editorActions.hideCommentAddForm();
  };

  return (
    <form className="comment-add-form" ref={ref}>
      <div className="comment-add-form__input">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="textarea-comment-text">Enter your comment below</label>
        <textarea
          id="textarea-comment-text"
          placeholder="Enter your comment here"
          value={text}
          onChange={handleTextChange}
          rows={2}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </div>
      <div className="comment-add-form__controls">
        <button type="button" className="btn-secondary" onClick={handleCancelClick}>
          Cancel
        </button>
        <button type="button" className="btn-primary" onClick={handleAddCommentClick}>
          Add comment
        </button>
      </div>
    </form>
  );
};

CommentAddForm.propTypes = {
  editorActions: PropTypes.shape({
    hideCommentAddForm: PropTypes.func.isRequired,
    addNewComment: PropTypes.func.isRequired,
  }).isRequired,
  editorSelectors: PropTypes.shape({
    selectEditor: PropTypes.func.isRequired,
  }).isRequired,
};

export default CommentAddForm;
